.anticon-search {
    color: red;
}

.ant-table {
    background: none;
}

.ant-table table {
    border-collapse: separate;
    border-spacing: 0 10px;
}

.ant-table-thead>tr>th {
    background-color: transparent !important;
    border: none;
    font-weight: 900;
}

.ant-table-row {
    border-radius: 10px;
    margin-bottom: 12px;
    box-shadow: 0 0 6px 0 rgba(6, 0, 1, 0.2);
    background-color: white;
    overflow: hidden;
}

.ant-table-tbody>tr>td:first-child {
    padding: 0;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.ant-table-tbody>tr>td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    white-space: nowrap;
}

@media (max-width: 500px) {
    .ant-table-cell:nth-child(6) {
        display: none;
    }
    /* .ant-table-thead {
        display: none;
    } */
    .ant-table-thead>tr>th,
    .ant-table-tbody>tr>td,
    .ant-table tfoot>tr>th,
    .ant-table tfoot>tr>td {
        padding: 5px;
        text-align: center;
        font-size: 11px;
    }
}