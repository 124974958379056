.ant-layout-header {
    background-color: white;
}

#header-right-menu-item {
    margin-right: 10px;
    float: right;
}

.ant-dropdown-trigger {
    float: right;
}
