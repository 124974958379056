.rc-footer-container {
    padding: 25px 0 10px;
}

.rc-footer-column {
    margin-bottom: 0px;
}

.rc-footer-column-icon {
    width: auto;
}

.rc-footer-item {
    color: white;
}
